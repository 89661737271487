import { ChangeEvent } from "react";

import { ReactComponent as DeleteIcon } from "assets/control-icons/xmark-solid.svg";
import { Label } from "components/form/Label";
import { RangeStepper } from "./RangeStepper";

import { Field, FieldValidator, useField } from "formik";
import { differenceInCalendarDays } from "date-fns";
import {
  initialDateFilterRange,
  RangeOptions,
  useDateRangeMapFilter,
} from "../DateRangeMapFilterProvider";
import { strictParseDate } from "utils/date";

interface Props {
  label: string;
  name: string;
  placeholderText: string;
  validator: FieldValidator;
}

/** An input component that shows date range selected or entered by users.
 *  It gives visual feedback when a preset date range or dates on calender got selected.
 *  Users can also freely type in a date range */
export const DateFilterRangeField = (props: Props) => {
  const { label, name, placeholderText, validator } = props;
  const [, meta, helpers] = useField(props.name);

  const {
    showDateFilterExpand,
    setDateFilterRange,
    setShowDateFilterExpand,
    setIsDateFilterInputActive,
    setActivePresetRangeOption,
    resetDateFilter,
  } = useDateRangeMapFilter();

  const onChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const dateInput = event.target.value;
    if (dateInput === undefined) return;

    // set value for the input field
    helpers.setValue(dateInput);

    // No matter which filter option it was before, if users started interacting with the input
    // make sure the date range option is Custom
    setActivePresetRangeOption(RangeOptions.Custom);

    // dateFilterRange is consumed by the calendar to show dates
    // update the value when a valid date is entered into the system
    // the DateFilterExpand will show corresponding feedback when
    // a valid date range is entered
    const dateRangeInput = dateInput.replace(/\s/g, "").split("-");
    const inputStartDate = dateRangeInput[0];
    const inputEndDate = dateRangeInput[1];

    // If input string is not a valid date, the parsed date will be null
    let formattedStartDate = strictParseDate(inputStartDate);
    formattedStartDate?.setHours(0, 0, 0, 0);
    let formattedEndDate = strictParseDate(inputEndDate);
    formattedEndDate?.setHours(0, 0, 0, 0);

    const hasDateRange =
      formattedEndDate !== null && formattedStartDate !== null;
    if (hasDateRange) {
      const diff = differenceInCalendarDays(
        formattedEndDate!,
        formattedStartDate!
      );
      const hasValidDateRange = hasDateRange && diff >= 0;
      if (hasValidDateRange) {
        setDateFilterRange({
          startDate: formattedStartDate,
          endDate: formattedEndDate,
          customDiff: diff,
        });
      } else {
        // empty the date filter range, unless a validate date is entered
        setDateFilterRange(initialDateFilterRange);
      }
    } else {
      setDateFilterRange(initialDateFilterRange);
    }
  };

  const onClearClicked = () => {
    resetDateFilter();
    helpers.setValue("");
  };

  return (
    <div className="d-flex mx-2 align-items-center">
      <Label htmlFor={name} label={label} />
      <div className="position-relative">
        <Field
          as="input"
          name={name}
          id={name}
          className="form-control date-filter-input"
          required={false} // Don't want browser default validation
          autoComplete="off"
          onChange={(e: ChangeEvent<HTMLInputElement>) => onChange(e)}
          onBlur={() => {
            setIsDateFilterInputActive(false);
          }}
          onClick={() => {
            setShowDateFilterExpand(!showDateFilterExpand);
            setIsDateFilterInputActive(true);
          }}
          onFocus={() => {
            setIsDateFilterInputActive(true);
          }}
          placeholder={placeholderText}
          value={meta.value}
          validate={validator}
        />

        {meta.value && (
          <i className="date-clear-icon">
            <DeleteIcon width={24} height={24} onClick={onClearClicked} />
          </i>
        )}
      </div>
      <RangeStepper dateRangeFieldName={name} />
    </div>
  );
};
