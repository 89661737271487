import { useField } from "formik";
import {
  RangeOptions,
  useDateRangeMapFilter,
} from "../DateRangeMapFilterProvider";
import classNames from "classnames";

export const DateFilterFeedback = ({
  dateRangeFieldName,
}: {
  dateRangeFieldName: string;
}) => {
  const [, meta] = useField(dateRangeFieldName);

  const { dateFilterRange, isDatesModifiedByUser, activePresetRangeOption } =
    useDateRangeMapFilter();

  const inputError = meta.error;

  const showDefault =
    !dateFilterRange?.startDate && !dateFilterRange?.endDate && !inputError;
  const completeRangeInstruction =
    dateFilterRange.startDate &&
    !dateFilterRange.endDate &&
    !showDefault &&
    !inputError;
  const stepperInstruction =
    dateFilterRange.startDate &&
    dateFilterRange.endDate &&
    isDatesModifiedByUser &&
    !inputError;
  const showPresetRangeInfo =
    !isDatesModifiedByUser &&
    activePresetRangeOption !== RangeOptions.Custom &&
    !inputError;
  const showCustomRangeInfo =
    !isDatesModifiedByUser &&
    dateFilterRange.startDate &&
    dateFilterRange.endDate &&
    activePresetRangeOption === RangeOptions.Custom &&
    !inputError;

  return (
    <div
      className={classNames("ms-2 mb-2 text-smaller", {
        "text-danger": inputError,
      })}
    >
      {inputError && <>{meta.error}</>}
      {showDefault && "Currently viewing: All current and future projects"}
      {completeRangeInstruction &&
        "Pick another date or a range to finish date range"}
      {stepperInstruction && (
        <p className="m-0">
          Click <strong>Apply</strong> to activate date filter and minimise the
          widget
        </p>
      )}
      {showPresetRangeInfo && (
        <p className="m-0">
          Currently viewing:{" "}
          <strong>
            {activePresetRangeOption} by {activePresetRangeOption}
          </strong>
        </p>
      )}
      {showCustomRangeInfo && (
        <p className="m-0">
          Currently viewing:{" "}
          {/* add one day to the date difference to show how many days covered by the date range */}
          <strong>
            {`custom range of ${dateFilterRange.customDiff + 1} day${dateFilterRange.customDiff === 0 ? "" : "s"}`}
          </strong>
        </p>
      )}
    </div>
  );
};
