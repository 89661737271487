import {
  createContext,
  useContext,
  useState,
  useCallback,
  useMemo,
} from "react";

// dates that re used by several components to filter or search feature
export interface DateInterface {
  startDate: Date | null;
  endDate: Date | null;
}

// dateFilterRange is only used by date filter map control
export interface DateFilterRange extends DateInterface {
  customDiff: number;
}

export enum RangeOptions {
  Custom = "Custom",
  Day = "Day",
  Week = "Week",
  Month = "Month",
  Quarter = "Quarter",
  Year = "Year",
}

interface DateRangeMapFilterContextInterface {
  dates: DateInterface; // dates for filtering feature on map
  dateFilterRange: DateFilterRange; //dates to update calendar UI
  activePresetRangeOption: RangeOptions;
  showDateFilterExpand: boolean;
  isDateFilterInputActive: boolean;
  isDatesModifiedByUser: boolean;
  setDates: React.Dispatch<React.SetStateAction<DateInterface>>;
  setDateFilterRange: React.Dispatch<React.SetStateAction<DateFilterRange>>;
  setActivePresetRangeOption: React.Dispatch<
    React.SetStateAction<RangeOptions>
  >;
  setShowDateFilterExpand: React.Dispatch<React.SetStateAction<boolean>>;
  setIsDateFilterInputActive: React.Dispatch<React.SetStateAction<boolean>>;
  resetDateFilter: () => void;
}

export const initialDateFilterRange = {
  startDate: null,
  endDate: null,
  customDiff: 0,
};

export const DateRangeMapFilterContext =
  createContext<DateRangeMapFilterContextInterface>(null!);

export const DateRangeMapFilterProvider = ({
  children,
}: {
  children: React.ReactNode;
}) => {
  const [dates, setDates] = useState<DateInterface>({
    startDate: null,
    endDate: null,
  });

  const [dateFilterRange, setDateFilterRange] = useState<DateFilterRange>(
    initialDateFilterRange
  );

  const [activePresetRangeOption, setActivePresetRangeOption] = useState(
    RangeOptions.Custom
  );

  const [showDateFilterExpand, setShowDateFilterExpand] = useState(true);

  const [isDateFilterInputActive, setIsDateFilterInputActive] = useState(false);

  // When dates are not set by user action, any interaction will set this value to True
  // After dates are set, this value set to False if users take one of these actions:
  // changed date range selected, changed preset range, cleared or modified the date input
  const isDatesModifiedByUser = useMemo(
    () =>
      Boolean(
        dates.startDate !== dateFilterRange.startDate ||
          dates.endDate !== dateFilterRange.endDate
      ),
    [dates, dateFilterRange]
  );

  const resetDateFilter = useCallback(() => {
    setDates({ startDate: null, endDate: null });
    setShowDateFilterExpand(true);
    setDateFilterRange(initialDateFilterRange);
    setActivePresetRangeOption(RangeOptions.Custom);
  }, []);

  const value = {
    dates,
    dateFilterRange,
    showDateFilterExpand,
    isDateFilterInputActive,
    activePresetRangeOption,
    isDatesModifiedByUser,
    setDates,
    setDateFilterRange,
    setShowDateFilterExpand,
    setIsDateFilterInputActive,
    setActivePresetRangeOption,
    resetDateFilter,
  };

  return (
    <DateRangeMapFilterContext.Provider value={value}>
      {children}
    </DateRangeMapFilterContext.Provider>
  );
};

export const useDateRangeMapFilter = () => {
  return useContext(DateRangeMapFilterContext);
};
