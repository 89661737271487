import { DateFilterOptionSelector } from "./DateFilterOptionSelector";
import { DateFilterCalendar } from "./DateFilterCalendar";

import { useField } from "formik";
import { useDateRangeMapFilter } from "../DateRangeMapFilterProvider";

import classNames from "classnames";

export const DateFilterExpand = ({ name }: { name: string }) => {
  const { showDateFilterExpand } = useDateRangeMapFilter();
  const [, meta] = useField(name);

  const { dateFilterRange } = useDateRangeMapFilter();

  const disableApply =
    !!meta.error || !dateFilterRange.startDate || !dateFilterRange.endDate;

  return (
    <div
      className={classNames("mx-2 mt-3", { "d-none": !showDateFilterExpand })}
    >
      <div className="row p-1 g-2 rounded-1 wrapper">
        <div className="col">
          <DateFilterOptionSelector dateRangeFieldName={name} />
        </div>
        <div className="col">
          <DateFilterCalendar dateRangeFieldName={name} />
        </div>
      </div>
      <button
        type="submit"
        className="btn btn-primary d-block mt-2 me-0 ms-auto"
        disabled={disableApply}
      >
        Apply
      </button>
    </div>
  );
};
