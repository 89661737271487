import { useMemo } from "react";
import { ReactComponent as ForwardIcon } from "assets/control-icons/forward-icon.svg";

import { useField } from "formik";
import {
  RangeOptions,
  useDateRangeMapFilter,
} from "../DateRangeMapFilterProvider";
import { DATE_FORMAT } from "utils/constants/date";
import { addDays, addMonths, format, subDays, subMonths } from "date-fns";

import classNames from "classnames";
import "./RangeStepper.scss";

enum Actions {
  GoBack = "back",
  GoForward = "forward",
}

export const RangeStepper = ({
  dateRangeFieldName,
}: {
  dateRangeFieldName: string;
}) => {
  const {
    dates,
    dateFilterRange,
    activePresetRangeOption,
    isDatesModifiedByUser,
    setDates,
    setDateFilterRange,
  } = useDateRangeMapFilter();

  const [, , helpers] = useField(dateRangeFieldName);

  // Only show RangeStepper when dates are set for map filtering and no modification incurred
  const showRangeStepper = useMemo(
    () => Boolean(dates.startDate && dates.endDate && !isDatesModifiedByUser),
    [dates, isDatesModifiedByUser]
  );

  const applyFilter = (action: string, range: string) => {
    if (dateFilterRange.startDate === null || dateFilterRange.endDate === null)
      return;

    let newDates: [Date, Date] = [
      dateFilterRange.startDate,
      dateFilterRange.endDate,
    ];

    if (range === RangeOptions.Custom) {
      // if start date and end date are the same, move both dates by one day
      const diff =
        dateFilterRange.customDiff === 0 ? 1 : dateFilterRange.customDiff;
      if (action === Actions.GoBack) {
        const newStart = subDays(dateFilterRange.startDate, diff);
        const newEnd = subDays(dateFilterRange.endDate, diff);
        newDates = [newStart, newEnd];
      }
      if (action === Actions.GoForward) {
        const newStart = addDays(dateFilterRange.startDate!, diff);
        const newEnd = addDays(dateFilterRange.endDate!, diff);
        newDates = [newStart, newEnd];
      }
    }

    if (range === RangeOptions.Day) {
      if (action === Actions.GoBack) {
        const newStart = subDays(dateFilterRange.startDate, 1);
        const newEnd = subDays(dateFilterRange.endDate, 1);
        newDates = [newStart, newEnd];
      }
      if (action === Actions.GoForward) {
        const newStart = addDays(dateFilterRange.startDate, 1);
        const newEnd = addDays(dateFilterRange.endDate, 1);
        newDates = [newStart, newEnd];
      }
    }

    if (range === RangeOptions.Week) {
      if (action === Actions.GoBack) {
        const newStart = subDays(dateFilterRange.startDate, 7);
        const newEnd = subDays(dateFilterRange.endDate, 7);
        newDates = [newStart, newEnd];
      }
      if (action === Actions.GoForward) {
        const newStart = addDays(dateFilterRange.startDate, 7);
        const newEnd = addDays(dateFilterRange.endDate, 7);
        newDates = [newStart, newEnd];
      }
    }

    if (range === RangeOptions.Month) {
      if (action === Actions.GoBack) {
        const newStart = subMonths(dateFilterRange.startDate, 1);
        const newEnd = subMonths(dateFilterRange.endDate, 1);
        newDates = [newStart, newEnd];
      }
      if (action === Actions.GoForward) {
        const newStart = addMonths(dateFilterRange.startDate, 1);
        const newEnd = addMonths(dateFilterRange.endDate, 1);
        newDates = [newStart, newEnd];
      }
    }

    if (range === RangeOptions.Quarter) {
      if (action === Actions.GoBack) {
        const newStart = subMonths(dateFilterRange.startDate, 3);
        const newEnd = subMonths(dateFilterRange.endDate, 3);
        newDates = [newStart, newEnd];
      }
      if (action === Actions.GoForward) {
        const newStart = addMonths(dateFilterRange.startDate, 3);
        const newEnd = addMonths(dateFilterRange.endDate, 3);
        newDates = [newStart, newEnd];
      }
    }

    if (range === RangeOptions.Year) {
      if (action === Actions.GoBack) {
        const newStart = subMonths(dateFilterRange.startDate, 12);
        const newEnd = subMonths(dateFilterRange.endDate, 12);
        newDates = [newStart, newEnd];
      }
      if (action === Actions.GoForward) {
        const newStart = addMonths(dateFilterRange.startDate, 12);
        const newEnd = addMonths(dateFilterRange.endDate, 12);
        newDates = [newStart, newEnd];
      }
    }

    setDateFilterRange({
      ...dateFilterRange,
      startDate: newDates[0],
      endDate: newDates[1],
    });

    // update the input text of DateFilterRangeField
    const startDateForInput = newDates[0]
      ? format(newDates[0], DATE_FORMAT)
      : "";
    const endDateForInput = newDates[1] ? format(newDates[1], DATE_FORMAT) : "";
    helpers.setValue(`${startDateForInput} - ${endDateForInput}`);
    // update the provider, so we can have real-time feature filtering when moving the date range
    setDates({
      startDate: newDates[0],
      endDate: newDates[1],
    });
  };

  return (
    <div className="row align-items-center m-auto float-end">
      <div
        className={classNames("col-6 text-center pe-2", {
          "d-none": !showRangeStepper,
        })}
      >
        <button
          type="button"
          className="btn btn-primary btn-round"
          title="Skip backward"
          aria-label="Skip backward"
          onClick={() => {
            applyFilter(Actions.GoBack, activePresetRangeOption);
          }}
        >
          <ForwardIcon className="backward" />
        </button>
      </div>
      <div
        className={classNames("col-6 text-center ps-2", {
          "d-none": !showRangeStepper,
        })}
      >
        <button
          type="button"
          className="btn btn-primary btn-round"
          title="Skip forward"
          aria-label="Skip forward"
          onClick={() =>
            applyFilter(Actions.GoForward, activePresetRangeOption)
          }
        >
          <ForwardIcon />
        </button>
      </div>
    </div>
  );
};
