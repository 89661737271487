import { useField } from "formik";

import { DATE_FORMAT } from "utils/constants/date";

import {
  DateFilterRange,
  RangeOptions,
  useDateRangeMapFilter,
} from "../DateRangeMapFilterProvider";

import { addDays, addMonths, format } from "date-fns";
import classNames from "classnames";

export const DateFilterOptionSelector = ({
  dateRangeFieldName,
}: {
  dateRangeFieldName: string;
}) => {
  const [, , helpers] = useField(dateRangeFieldName);

  const {
    dateFilterRange,
    setDateFilterRange,
    activePresetRangeOption,
    setActivePresetRangeOption,
  } = useDateRangeMapFilter();

  const rangeOptions: RangeOptions[] = Object.values(RangeOptions);

  const rangeOptionsWithoutCustom = rangeOptions.filter(
    (option) => option !== RangeOptions.Custom
  );

  const onClick = (selectedOption: RangeOptions) => {
    // make selected option active no matter users clicked on same active option or a previous non-active action
    setActivePresetRangeOption(selectedOption);
    // store new date range in this variable according user interaction between the filter selector and calendar

    let updatedDates: DateFilterRange = {
      startDate: null,
      endDate: null,
      customDiff: 0,
    };

    // for Custom option, we won't be able to work out a range without both start and end dates
    if (selectedOption === RangeOptions.Custom) {
      if (dateFilterRange.startDate && dateFilterRange.endDate) {
        updatedDates = {
          startDate: dateFilterRange.startDate,
          endDate: dateFilterRange.endDate,
          customDiff: dateFilterRange.customDiff,
        };
      }
      // date-picker calendar provide visual feedback for user action, so need to store dates
      // until a valid date range is selected by user
      if (dateFilterRange.startDate && dateFilterRange.endDate === null) {
        updatedDates = {
          startDate: null,
          endDate: null,
          customDiff: 0,
        };
      }
    } else {
      // If no user interaction on calendar and user picked a preset range filter, then use today as start date
      const startDate = dateFilterRange.startDate ?? new Date();
      if (selectedOption === RangeOptions.Day) {
        updatedDates = {
          startDate: startDate,
          endDate: startDate,
          customDiff: 0,
        };
      }
      if (selectedOption === RangeOptions.Week) {
        updatedDates = {
          startDate: startDate,
          endDate: addDays(startDate, 7),
          customDiff: 0,
        };
      }
      if (selectedOption === RangeOptions.Month) {
        updatedDates = {
          startDate: startDate,
          endDate: addMonths(startDate, 1),
          customDiff: 0,
        };
      }
      if (selectedOption === RangeOptions.Quarter) {
        updatedDates = {
          startDate: startDate,
          endDate: addMonths(startDate, 3),
          customDiff: 0,
        };
      }
      if (selectedOption === RangeOptions.Year) {
        updatedDates = {
          startDate: startDate,
          endDate: addMonths(startDate, 12),
          customDiff: 0,
        };
      }
    }

    // use the updatedDates to set dateFilterRange for the calendar
    setDateFilterRange(updatedDates);

    //update the input with same values showing in calendar
    const startDateForInput = updatedDates.startDate
      ? format(updatedDates.startDate, DATE_FORMAT)
      : "";
    const endDateForInput = updatedDates.endDate
      ? format(updatedDates.endDate, DATE_FORMAT)
      : "";
    if (!startDateForInput && !endDateForInput) {
      helpers.setValue("");
    } else {
      helpers.setValue(`${startDateForInput} - ${endDateForInput}`);
    }
  };

  const showDaysDiff =
    activePresetRangeOption === RangeOptions.Custom &&
    dateFilterRange.customDiff >= 0 &&
    dateFilterRange.startDate &&
    dateFilterRange.endDate;

  return (
    <>
      <div className="text-smallest fw-400 mb-2">
        Select a preset range below or use the calendar to select a custom range
        of dates
      </div>
      <div className="fw-600 mb-2">Date range presets: </div>
      <div className="list-group mb-2">
        <ul className="list-group">
          {rangeOptionsWithoutCustom.map((option) => {
            return (
              <li
                className={classNames(
                  "list-group-item px-0 bg-transparent fw-500 range-option",
                  { "fw-700 active": option === activePresetRangeOption }
                )}
                key={`${option}-filter`}
                onClick={() => onClick(option)}
                style={{}}
              >
                {option}
              </li>
            );
          })}
        </ul>
      </div>
      <div>
        <span className="fw-600">{`${RangeOptions.Custom} range: `}</span>
        {/* add one day to the date difference to show how many days covered by the date range */}
        {showDaysDiff ? (
          <div
            className={classNames("fw-normal", {
              "text-danger": dateFilterRange.customDiff < 0,
            })}
          >{`${dateFilterRange.customDiff + 1} day${dateFilterRange.customDiff === 0 ? "" : "s"}`}</div>
        ) : (
          ""
        )}
      </div>
    </>
  );
};
