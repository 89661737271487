import DatePicker from "react-datepicker";
import { subDays, differenceInCalendarDays, format } from "date-fns";
import { useField } from "formik";
import { DATE_FORMAT } from "utils/constants/date";
import {
  RangeOptions,
  useDateRangeMapFilter,
} from "../DateRangeMapFilterProvider";

export const DateFilterCalendar = ({
  dateRangeFieldName,
}: {
  dateRangeFieldName: string;
}) => {
  const [, , helpers] = useField(dateRangeFieldName);

  const { dateFilterRange, setDateFilterRange, setActivePresetRangeOption } =
    useDateRangeMapFilter();

  const onChange = (dates: [Date | null, Date | null]) => {
    const [start, end] = dates;

    //update the input with same values selected in calendar
    const startDateForInput = start ? format(start, DATE_FORMAT) : "";
    const endDateForInput = end ? format(end, DATE_FORMAT) : "";
    if (!startDateForInput && !endDateForInput) {
      helpers.setValue("");
    } else {
      helpers.setValue(`${startDateForInput} - ${endDateForInput}`);
    }

    // selected a dateFilterRange
    if (start && end) {
      setDateFilterRange({
        startDate: start,
        endDate: end,
        customDiff: differenceInCalendarDays(end, start),
      });

      // No matter which filter option it was before, if users picked a date range by interacting with the calendar,
      // make sure the filter option is Custom
      setActivePresetRangeOption(RangeOptions.Custom);
    } else {
      // end date not selected yet
      setDateFilterRange({
        ...dateFilterRange,
        startDate: start,
        endDate: end,
        customDiff: 0,
      });
    }
  };

  const handleDateHighlight = (date: Date) => {
    if (dateFilterRange.startDate === null || dateFilterRange.endDate === null)
      return null;
    const myClass: string = "bg-transparent";
    // date-picker always highlights the end date when using range selection, this will disable the highlight
    return date < subDays(dateFilterRange.startDate, 1) ||
      date > dateFilterRange.endDate
      ? myClass
      : null;
  };

  return (
    <div className="text-center">
      <DatePicker
        onChange={onChange}
        selected={dateFilterRange.startDate ?? new Date()}
        startDate={dateFilterRange.startDate}
        endDate={dateFilterRange.endDate}
        open={true}
        strictParsing
        selectsRange
        disabledKeyboardNavigation
        dayClassName={handleDateHighlight}
        inline
        fixedHeight
      />
    </div>
  );
};
