import classNames from "classnames";

type Dropdown = "date-filter" | "location" | "quick-search" | "";

interface MapFilterControlsHeaderButtonProps {
  id: Dropdown;
  icon: React.ReactNode;
  title: string;
  active: boolean;
  small?: boolean;
  onClick?: () => void;
}

export const MapFilterControlsHeaderButton = ({
  id,
  icon,
  title,
  active,
  small = false,
  onClick,
}: MapFilterControlsHeaderButtonProps) => (
  <button
    className={classNames("map-controls-header-button", {
      active,
      "rounded-pill": !small,
      "rounded-circle": small,
    })}
    style={{ width: small ? 44 : "auto" }}
    type="button"
    aria-expanded={active}
    aria-controls={id}
    title={title}
    data-bs-toggle="tooltip"
    data-bs-placement="bottom"
    onClick={onClick}
  >
    {small ? (
      icon
    ) : (
      <div className="d-flex align-items-center gap-1 px-3">
        {icon}
        <strong>{title}</strong>
      </div>
    )}
  </button>
);
