import { useState, useMemo, useEffect } from "react";

import { ReactComponent as LocationIcon } from "assets/sidebar-icons/search.svg";
import { ReactComponent as CalendarIcon } from "assets/other-icons/calendar.svg";
import Collapse from "react-bootstrap/Collapse";
import { MapFilterControlsHeaderButton } from "./MapFilterControlsHeaderButton";
import { LocationControl } from "./LocationControl";
import { DateFilterControl } from "./DateFilter/DateFilterControl";

import { useMapToggle } from "components/map/useMapToggle";
import {
  Panel,
  PanelVisibility,
  usePanels,
} from "components/panel/PanelsProvider";

import "./MapControls.scss";

export type Dropdown = "date-filter" | "location" | "quick-search" | "";

export const MapFilterControls = () => {
  const [openDropdown, setOpenDropdown] = useState<Dropdown>("");

  const [enableMap, disableMap] = useMapToggle();
  const { panels } = usePanels();

  const small = useMemo(() => {
    const panelWidth = 448; // width of a panel
    const expandedPanels = panels.filter(
      (p: Panel) => p.visibility === PanelVisibility.Expanded
    );
    const expandedPanelsWidth = (expandedPanels.length + 0.5) * panelWidth; // include half a panel worth of extra content
    return window.innerWidth - expandedPanelsWidth < panelWidth;
  }, [panels]);

  useEffect(() => {
    // close expanded map control when viewport gets too small
    if (small) {
      setOpenDropdown("");
    }
  }, [small]);

  const content = {
    location: <LocationControl />,
    "date-filter": <DateFilterControl />,
    "quick-search": null,
    "": null,
  }[openDropdown];

  return (
    <div
      className="map-controls"
      onMouseOver={disableMap}
      onMouseOut={enableMap}
    >
      <div className="d-flex gap-3 justify-content-end">
        <MapFilterControlsHeaderButton
          id="location"
          title="Location"
          small={small}
          active={openDropdown === "location"}
          icon={<LocationIcon width={26} height={26} />}
          onClick={() => {
            setOpenDropdown(openDropdown === "location" ? "" : "location");
          }}
        />
        <MapFilterControlsHeaderButton
          id="date-filter"
          title="Date Filter"
          small={small}
          active={openDropdown === "date-filter"}
          icon={<CalendarIcon width={18} height={22} />}
          onClick={() => {
            setOpenDropdown(
              openDropdown === "date-filter" ? "" : "date-filter"
            );
          }}
        />
      </div>

      <Collapse in={openDropdown !== ""}>
        <div className="map-controls-content p-2 my-3 rounded-3">{content}</div>
      </Collapse>
    </div>
  );
};
