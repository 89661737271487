import { useEffect } from "react";
import { useNavigate } from "react-router-dom";

import { Formik, Form } from "formik";
import { DateFilterRangeField } from "pages/root/Map/map-controls/DateFilter/DateFilterRangeField";
import { DateFilterFeedback } from "./DataFilterFeedback";
import { DateFilterExpand } from "./DateFIlterExpand";

import { useDateRangeMapFilter } from "../DateRangeMapFilterProvider";
import { Path } from "utils/constants/paths";
import { buildFormConfig } from "utils/form";
import { FormFieldConfig } from "utils/types/form";

import { differenceInCalendarDays } from "date-fns";
import { strictParseDate } from "utils/date";

import "./DateFilter.scss";

const dateFilterConfig: FormFieldConfig = {
  name: "dateInput",
  initialValue: "",
};

export const DateFilterControl = () => {
  const {
    dateFilterRange,
    setDates,
    setShowDateFilterExpand,
    resetDateFilter,
  } = useDateRangeMapFilter();
  const { isDateFilterInputActive } = useDateRangeMapFilter();
  const { initialValues } = buildFormConfig(dateFilterConfig);
  const navigate = useNavigate();

  // Only free typed-in dates need validation. Validation starts when input is focused or clicked
  function validateDateInput(value: string) {
    if (!isDateFilterInputActive) return;
    const dateRangeInput = value.replace(/\s/g, "").split("-");
    const inputStartDate = dateRangeInput[0];
    const inputEndDate = dateRangeInput[1];

    // If input string is not a valid date, the parsed date will be null
    let formattedStartDate = strictParseDate(inputStartDate);
    formattedStartDate?.setHours(0, 0, 0, 0);
    let formattedEndDate = strictParseDate(inputEndDate);
    formattedEndDate?.setHours(0, 0, 0, 0);

    // only validate the input when input is long enough
    const startValidation = value.length > 10;

    const hasDateRange =
      formattedStartDate !== null && formattedEndDate !== null;

    const diff = differenceInCalendarDays(
      formattedEndDate!,
      formattedStartDate!
    );
    const hasValidDateRange = hasDateRange && diff >= 0;

    if (startValidation) {
      if (!hasDateRange) {
        return "Please provide a valid date range of dd/mm/yyyy - dd/mm/yyyy";
      }
      if (!hasValidDateRange) {
        return "Please make sure start date is no later than end date";
      }
    }
    return "";
  }

  useEffect(() => {
    return () => {
      resetDateFilter();
    };
  }, [resetDateFilter]);

  return (
    <Formik
      initialValues={initialValues}
      onSubmit={() => {
        /**
         * Close the project drawer so that a project is no longer selected on
         * the map. This ensures that we don't get a false positive where a
         * project that is outside of the search date range does not remain
         * selected on the map after the Search button is clicked.
         */
        navigate(Path.Index);
        setDates({
          startDate: dateFilterRange.startDate,
          endDate: dateFilterRange.endDate,
        });
        setShowDateFilterExpand(false);
      }}
    >
      <Form>
        <DateFilterFeedback dateRangeFieldName={dateFilterConfig.name} />
        <DateFilterRangeField
          label="Date Range"
          name={dateFilterConfig.name}
          placeholderText="dd/mm/yyyy - dd/mm/yyyy"
          validator={validateDateInput}
        />
        <DateFilterExpand name={dateFilterConfig.name} />
      </Form>
    </Formik>
  );
};
